<template>
  <div class="rk-footer">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="d-flex align-items-center justify-content-center pb-3">
            <img
              src="@/assets/images/rezkit-logo-ticket-gradient.png"
              class="text-center rk-footer__logo me-3"
              alt="© Rezkit Ltd"
            />
            <h4 class="rk-title rk-title--light">
              Flexible tour booking software for travel pioneers
            </h4>
          </div>
          <div class="text-center pb-3">
            <p class="rk-text rk-text--text-xs">
              Registered company: RezKit Ltd, No. 13842422<br />Incorporated on
              11 January 2022 <br />Registered office: Office 10, Chieftain
              House, Bordon, England, GU35 0FQ
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid">
      <div class="row">
        <div class="rk-footer__copyright">
          <div class="container">
            <div class="row">
              <div class="col-12">
                <p class="text-center py-3 m-0">
                  ©{{ copyrightYear }} Rezkit Ltd
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
export default defineComponent({
  data() {
    const copyrightYear: number = new Date().getFullYear()

    return {
      copyrightYear,
    }
  },
})
</script>

<style lang="scss"></style>
